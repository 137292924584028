<template>
  <div class="d-flex flex-column align-items-center">
    <img
      src="@/assets/images/doctor-online.png"
    />
    <span class="my-2">
            {{ message }}
        </span>
    <b-button

      variant="primary"
      @click="goToSettings"
    >
       {{ buttonTitle }}
    </b-button>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Não há um código Owner Labpacs configurado no sistema'
    },
    buttonTitle: {
      type: String,
      default: 'Configurar Owner'
    }
  },
  methods: {
    goToSettings() {
      window.location.replace('/configuracoes-da-clinica/integrations')
    }
  }
}
</script>